import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Services from "../components/services"
import SEO from "../components/seo"
import { Link } from "gatsby"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />

    <section className="banner">
      <StaticImage
        className="banner__image"
        src="../images/young-red-cow.jpg"
        alt="Young red cow"
        loading="eager"
        objectPosition="100% 100%"
      />
      <div className="banner__content">
        <h1 className="h2 mb-4 section-heading section-heading--left">
          Preventative Animal Health
        </h1>
        <p>
          Experienced, gold standard, veterinary-based animal health care for
          your lifestyle block animals.
        </p>
        <p>
          Let us be your fence at the top of the cliff so you don’t need an
          ambulance at the bottom. A preventative approach to animal health
          means your animals are less likely to get sick and require costly
          care. Our animal health plans give you peace of mind and take the
          guess work out of managing your lifestyle block animals.
        </p>
        <p className="mb-5">
          From vaccinations to NAIT compliance, hoof care to pasture management,
          Lakeside Lifestyle is here to help!
        </p>
        <Link to="/contact/" className="btn btn-secondary">
          Get in touch
        </Link>
      </div>
    </section>

    <section className="bg-light">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-md-6 align-items-center d-flex order-last">
            <div>
              <h2 className="section-heading section-heading--left">
                About Us
              </h2>
              <p>
                Lakeside Lifestyle provides top-notch animal care and disease
                prevention for all of your lifestyle block animals. Whether you
                own 10 acres or 2, own 6 sheep or Noah’s Ark, we understand the
                pressures of owning a lifestyle block. Our experienced and
                highly trained team are here to ensure that the health and
                welfare of your animals is taken care of so you can spend more
                time enjoying your animals than worrying about them!
              </p>
              <Link className="btn btn-secondary mt-3" to="/meet-the-team/">
                Meet the team
              </Link>
            </div>
          </div>
          <div className="col-md-6 col-lg-5 mb-4 mb-lg-0 d-flex align-items-center">
            <StaticImage
              aspectRatio={16 / 13}
              src="../images/highland-cow.jpg"
              alt="Highland cow"
              loading="eager"
            />
          </div>
        </div>
      </div>
    </section>

    <Services showTitle />
  </Layout>
)

export default IndexPage
