import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const Services = ({ showTitle }) => (
  <section id="services" className="">
    <div className="container">
      {showTitle && <h2 className="section-heading">Services</h2>}
      <div className="row justify-content-center">
        <div className="col-md-6 col-lg-5 mb-5">
          <Link
            to="/services/preventative-health-services/"
            className="card-link"
          >
            <div className="card shadow">
              <StaticImage
                className="card-img-top"
                aspectRatio={16 / 11}
                src="../images/piglets.jpg"
                alt="Piglets feeding from trough"
              />
              <div className="card-body">
                <h5 className="card-title">Preventative Health Services</h5>
                <p className="card-text">
                  Keeping your animals healthy through optimising timing and
                  intervention is our priority! Remove the guesswork, we’ve got
                  this!
                </p>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-md-6 col-lg-5 mb-5">
          <Link to="/services/animal-health-plans/" className="card-link">
            <div className="card shadow">
              <StaticImage
                className="card-img-top"
                aspectRatio={16 / 11}
                src="../images/farm-goats.jpg"
                alt="Farm goats"
              />
              <div className="card-body">
                <h5 className="card-title">Animal Health Plans</h5>
                <p className="card-text">
                  Are you unsure of what your animals require to be healthy and
                  protected from disease? We can create a customised animal
                  health plan for your animals to ensure they are in tip-top
                  health.
                </p>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-md-6 col-lg-5 mb-5">
          <Link to="/services/lifestyle-block-package/" className="card-link">
            <div className="card shadow">
              <StaticImage
                className="card-img-top"
                aspectRatio={16 / 11}
                src="../images/sheep.jpg"
                alt="Sheep"
              />
              <div className="card-body">
                <h5 className="card-title">Lifestyle Block Start-Up Package</h5>
                <p className="card-text">
                  New to a lifestyle block? Unsure of what animals fit your
                  needs? Unanswered questions about yards, shelter or troughs?
                  We can help!
                </p>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-md-6 col-lg-5 mb-4 mb-md-5">
          <Link to="/services/nait-compliance/" className="card-link">
            <div className="card shadow">
              <StaticImage
                className="card-img-top"
                aspectRatio={16 / 11}
                src="../images/brown-cow.jpg"
                alt="Brown cow"
              />
              <div className="card-body">
                <h5 className="card-title">NAIT Compliance</h5>
                <p className="card-text">
                  Knickers in a twist about having your cattle NAIT compliant?
                  Time to give the team at Lakeside Lifestyle a call.{" "}
                </p>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  </section>
)

export default Services
